import React, {
  FunctionComponent,
  LazyExoticComponent,
  ReactElement,
  Suspense,
} from 'react';
import FullScreenSpinner from 'core/components/FullScreenSpinner';

export function lazyLoadComponent(Component: LazyExoticComponent<FunctionComponent>): ReactElement {
  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <Component />
    </Suspense>
  );
}
